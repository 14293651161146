<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-skeleton-loader
            v-for="n in 4"
            :key="n"
            class="mx-auto"
            type="list-item-avatar-three-line"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="!isLoading && isValid">
        <v-col
          cols="3"
          v-for="(item, index) in team.relations.leaders"
          :key="index"
        >
          <div class="avatar-info">
            <v-avatar color="grey darken-3">
              <img v-if="item.avatar" :src="item.avatar" />
              <v-icon v-if="!item.avatar" dark> mdi-account-circle </v-icon>
            </v-avatar>
          </div>

          <div class="leader-info">
            <span class="leader-name">{{ item.name }}</span>
            <span>Líder</span>
          </div>
        </v-col>
      </v-row>

      <f-reports-view :teamsReport="this.id"></f-reports-view>
    </f-container>
  </div>
</template>

<script lang="js">
import FTeamContent from "../../components/Teams/view/teamContent.vue"
import TeamMember from "../../components/Teams/TeamMember.vue";
import ReportsViewTeam from "../../components/Reports/ReportsViewTeam.vue";
import { UserType } from "../../helpers/user_utils";

export default {
  components: {
    "f-reports-team": TeamMember,
    "f-reports-view": ReportsViewTeam,
    FTeamContent
  },
  created() {
    this.get();
  },
  computed: {
    title() {
      try {
        return this.team.name;
      } catch (e) {
        return this.$t('labels.teams');
      }
    },
    prevLinks() {
      return [
        {
          title: this.$t('labels.teams'),
          route: {
            name: 'team-list',
          },
        },
      ];
    },
    isManager() {
      return this.$store.state.auth.user.type == UserType.Admin || this.$store.state.auth.user.type == UserType.Master;
    },
    statusLabel() {
      return this.$i18n.t(`labels.status.${this.team.status}`);
    },
    statusColor() {
      return this.team.status === "enabled" ? "green" : "gray";
    },
    team() {
      return this.$store.getters["team/getData"];
    },
    isLoading() {
      if (this.$store.getters["team/getLoadingListData"]) {
        return true;
      }
      return false;
    },
    isValid() {
      return !!this.team._id;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    async get() {
      const result = await this.$store.dispatch("team/get", this.id);
      if (!result) {
        this.$router.push({ name: "team-list" });
      }
    },
    edit() {
      this.$router.push({ name: "team-edit", params: { id: this.id } });
    },
    back() {
      this.$router.push({ name: "team-list" });
    },
  },
  destroyed() {
    this.$store.dispatch("team/clear")
  }
};
</script>

<style lang="scss" scoped>
.avatar-info {
  float: left;
}
.leader-info {
  margin-left: 70px;
  display: grid;
}
.leader-name {
  font-weight: bold;
  font-size: 18px;
}
.team-view__content {
  margin-top: 50px;
}
</style>
