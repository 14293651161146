<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :server-items-length="itemsCount"
    >
      <template v-slot:top> </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    itemsCount: Number,
  },
  computed: {
    headers() {
      return [
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        {
          text: "Cursos",
          align: "start",
        },
        {
          text: "Finalizados",
          align: "start",
        },
      ];
    },
  },
};
</script>

