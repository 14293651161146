import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('f-page-header',{attrs:{"title":_vm.title,"prevLinks":_vm.prevLinks}}),_c('f-container',{attrs:{"topBottom":""}},[(_vm.isLoading)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},_vm._l((4),function(n){return _c(VSkeletonLoader,{key:n,staticClass:"mx-auto",attrs:{"type":"list-item-avatar-three-line"}})}),1)],1):_vm._e(),(!_vm.isLoading && _vm.isValid)?_c(VRow,_vm._l((_vm.team.relations.leaders),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"3"}},[_c('div',{staticClass:"avatar-info"},[_c(VAvatar,{attrs:{"color":"grey darken-3"}},[(item.avatar)?_c('img',{attrs:{"src":item.avatar}}):_vm._e(),(!item.avatar)?_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")]):_vm._e()],1)],1),_c('div',{staticClass:"leader-info"},[_c('span',{staticClass:"leader-name"},[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("Líder")])])])}),1):_vm._e(),_c('f-reports-view',{attrs:{"teamsReport":this.id}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }