<template>
  <div>
    <v-toolbar dark flat>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.key">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="TABS_KEYS.students">
				<f-students-list />
			</v-tab-item>
      <v-tab-item :key="TABS_KEYS.courses">
        <f-courses-list />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="js">
import FCoursesList from './coursesList.vue';
import FStudentsList from "./studentsList.vue";

const TABS_KEYS = {
	courses: 'courses',
	students: 'students',
}

export default {
	components: {
		FCoursesList,
		FStudentsList
	},
	data: () => ({
		tab: 0,
		TABS_KEYS
	}),
	computed: {
		tabs() {
      const items = [
				{
					key: this.TABS_KEYS.students,
					label: this.$t("labels.filterStudents"),
				},
        {
          key: this.TABS_KEYS.courses,
          label: this.$t("labels.courses"),
        },
      ];
      return items;
    },
	}
}
</script>
