<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat>
        <v-tabs v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tab of tabs" :key="tab.key">
            {{ $tt.capitalize(tab.label) }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tab of tabs" :key="tab.key">
          <report-course-list
            v-if="tab.key === 'courses'"
            :list="courseList"
            :itemsCount="courseItemsCount"
            :page="page[0]"
            :loading="!flags.loaded[0]"
            :countries="countries"
            :is-master="$store.state.user.isMaster"
            :user-masters="$store.state.user.masters"
            :downloading-ids="courseDownloadingIds"
            :hasTeam="teamsReport"
            @changePage="onChangeCoursePage"
            @changeItemsPerPage="onChangeCourseItemsPerPage"
            @search="onChangeCourseSearch"
            @changeMaster="onChangeCourseMaster"
            @export="onDownloadCourse"
          >
            <v-btn
              slot="export"
              color="primary"
              block
              large
              :loading="reportDownloading"
              :disabled="reportDownloading"
              @click.prevent="onDownloadCourseTeam"
            >
              <v-icon>mdi-download</v-icon>
              <span>{{ $i18n.t("labels.exportXLS") }}</span>
            </v-btn>
          </report-course-list>
          <report-student-list
            v-if="tab.key === 'students'"
            :list="studentList"
            :itemsCount="studentItemsCount"
            :page="page[1]"
            :loading="!flags.loaded[1]"
            :countries="countries"
            :is-master="$store.state.user.isMaster"
            :user-masters="$store.state.user.masters"
            :downloading="reportDownloading"
            :hasTeam="teamsReport"
            @changePage="onChangeStudentPage"
            @changeItemsPerPage="onChangeStudentItemsPerPage"
            @search="onChangeStudentSearch"
            @changeMaster="onChangeStudentMaster"
          >
            <v-btn
              slot="export"
              color="primary"
              block
              large
              :loading="reportDownloading"
              :disabled="reportDownloading"
              @click.prevent="onDownloadStudents"
            >
              <v-icon>mdi-download</v-icon>
              <span>{{ $i18n.t("labels.exportXLS") }}</span>
            </v-btn>
          </report-student-list>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import ReportCourseList from "./ReportCourseList.vue";
import ReportStudentList from "./ReportStudentList.vue";
import courseUtils from "../../helpers/course_utils";
import fileUtils from "../../helpers/file_utils";

export default {
  props: {
    teamsReport: String,
  },
  components: {
    ReportCourseList,
    ReportStudentList,
  },
  data() {
    return {
      flags: {
        loaded: {
          0: false,
          1: false,
        },
      },
      tab: 0,
      page: {
        0: 1,
        1: 1,
      },
      itemsPerPage: {
        0: 10,
        1: 10,
      },
      search: {
        0: "",
        1: "",
      },
      masterIds: {
        0: [],
        1: [],
      },
      courseDownloadingIds: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([
        this.loadCourseData(),
        this.loadStudentData(),
        this.loadMasterData(),
      ]);
    },
    async loadCourseData() {
      this.flags.loaded[0] = false;
      await this.$store.dispatch("report/loadCourseListTeam", {
        limit: this.itemsPerPage[0],
        page: this.page[0],
        search: this.search[0],
        master_ids: this.masterIds[0],
        teamsReport: this.teamsReport,
      });
      this.flags.loaded[0] = true;
    },
    async loadStudentData() {
      this.flags.loaded[1] = false;
      await this.$store.dispatch("report/loadStudentListTeam", {
        limit: this.itemsPerPage[1],
        page: this.page[1],
        search: this.search[1],
        master_ids: this.masterIds[1],
        teamsReport: this.teamsReport,
      });
      this.flags.loaded[1] = true;
    },
    async loadMasterData() {
      await this.$store.dispatch("country/loadList");
    },
    onChangeCoursePage(value) {
      if (+this.page[0] !== +value) {
        this.page[0] = value;
        this.loadCourseData();
      }
    },
    onChangeCourseItemsPerPage(value) {
      if (+this.itemsPerPage[0] !== +value) {
        this.itemsPerPage[0] = value;
        this.loadCourseData();
      }
    },
    onChangeCourseSearch(value) {
      if (this.search[0] !== value) {
        this.search[0] = value;
        this.page[0] = 1;
        this.loadCourseData();
      }
    },
    onChangeCourseMaster(masterIds) {
      this.masterIds[0] = masterIds;
      this.page[0] = 1;
      this.loadCourseData();
    },
    onChangeStudentPage(value) {
      if (+this.page[1] !== +value) {
        this.page[1] = value;
        this.loadStudentData();
      }
    },
    onChangeStudentItemsPerPage(value) {
      if (+this.itemsPerPage[1] !== +value) {
        this.itemsPerPage[1] = value;
        this.loadStudentData();
      }
    },
    onChangeStudentSearch(value) {
      if (this.search[1] !== value) {
        this.search[1] = value;
        this.page[1] = 1;
        this.loadStudentData();
      }
    },
    onChangeStudentMaster(masterIds) {
      this.masterIds[1] = masterIds;
      this.page[1] = 1;
      this.loadStudentData();
    },
    async onDownloadStudents() {
      const result = await this.$store.dispatch("report/downloadStudents", {
        search: this.search[1],
        master_ids: this.masterIds[1],
      });
      fileUtils.download({
        name: "report-students.xlsx",
        type: "application/octet-stream",
        data: result,
      });
    },
    async onDownloadCourse(courseId) {
      this.courseDownloadingIds.push(courseId);
      const result = await this.$store.dispatch("report/downloadCourse", {
        course_id: courseId,
        search: this.search[0],
        master_ids: this.masterIds[0],
      });
      fileUtils.download({
        name: "report-course.xlsx",
        type: "application/octet-stream",
        data: result,
      });
      this.courseDownloadingIds = this.courseDownloadingIds.filter((id) => {
        return id !== courseId;
      });
    },
    async onDownloadCourseTeam() {
      const result = await this.$store.dispatch("report/downloadCourseTeam", {
        teamId: this.teamsReport,
        master_ids: this.masterIds[0],
      });
      fileUtils.download({
        name: "report-course-team.xlsx",
        type: "application/octet-stream",
        data: result,
      });
    },

  },
  computed: {
    courseList() {
      const { report } = this.$store.state;
      return report.courseList.map((item) => {
        return {
          _id: item.course._id,
          course: courseUtils.courseTitle(item.course, this.$i18n.locale),
          students_count: item.stats.total,
          students_progress: item.stats.progress,
          students_done: item.stats.done,
          belongsToTeam: item.belongsToTeam,
        };
      });
    },
    courseItemsCount() {
      const { report } = this.$store.state;
      return report.courseItemsCount;
    },
    studentList() {
      const { report } = this.$store.state;
      return report.studentList.map((item) => {
        return {
          _id: item.student._id,
          name: item.student.name,
          courses_count: item.stats.total,
          courses_done: item.stats.done,
          belongsToTeam: item.belongsToTeam,
        };
      });
    },
    studentItemsCount() {
      const { report } = this.$store.state;
      return report.studentItemsCount;
    },
    tabs() {
      const items = [
        {
          key: "courses",
          label: this.$t("labels.courses"),
        },
        {
          key: "students",
          label: this.$t("labels.filterStudents"),
        },
      ];
      return items;
    },
    countries() {
      return this.$store.state.country.list;
    },
    reportDownloading() {
      return this.$store.state.report.downloading;
    },
  },
};
</script>
