<template>
  <div>
    {{ (itemsCount, options, page) }}
    {{ list }}
    <v-data-table
      :headers="headers"
      :items="list"
      :server-items-length="itemsCount"
      :options.sync="options"
      :loading="isLoading"
      :page="page"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
      }"
      class="elevation-0"
    />
  </div>
</template>

<script lang="js">
import apiUtils from '../../../helpers/api_utils';

export default {
	data: () => ({
		list: [],
		itemsCount: 0,
		options: {},
		page: 1
	}),
	computed: {
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t("reports.student.name")),
          align: "start",
          value: "name",
        },
        {
          text: this.$tt.capitalize(this.$t("reports.student.coursesCount")),
          align: "end",
          value: "doing",
        },
        {
          text: this.$tt.capitalize(this.$t("reports.student.coursesDone")),
          align: "end",
          value: "done",
        },
      ];
    },
	},
	watch: {
    options: {
      handler: function (values, old) {
        if (values.page !== old.page) {
          this.get()
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.get()
        }
      },
      deep: true,
    },
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			const result = await apiUtils.get(
				'/team/load-members',
				{
					teamID: "635308a3902e2aef605d8e7b",
					limit: this.options.itemsPerPage,
					page: this.options.page
				},
				this.$store.getters['auth/userToken']
			)

			if(result.status){
				this.list = result.body
			}
		}
	}
}
</script>
