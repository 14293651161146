import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VToolbar,{attrs:{"dark":"","flat":""}},[_c(VTabs,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key},[_vm._v(" "+_vm._s(tab.label)+" ")])})],2)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:_vm.TABS_KEYS.students},[_c('f-students-list')],1),_c(VTabItem,{key:_vm.TABS_KEYS.courses},[_c('f-courses-list')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }